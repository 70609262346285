import React from "react";
import "./styles/FAQs.css";
import FAQSection from "./FAQSection";
import FAQ from "./FAQ";

export default function FAQs() {
  return (
    <div id="faqs">
      <FAQSection title="GENERAL">
        <FAQ question="Are your services currently available for hire?">
          Yes! I'm always eager to take on new projects, establish valuable
          relationships, and explore new creative opportunities. Feel free to
          reach out to me by sending a message via the{" "}
          <a href="#contact">contact form</a>.
        </FAQ>
        <FAQ question="What media formats and genres do you create compositions for?">
          I take great pleasure in composing music across diverse media formats
          and genres. Whether it's for television, cinema, video games, short
          films, commercials, or any creative project, I possess the ability to
          create custom compositions that suit your specific needs in any
          desired genre.
        </FAQ>
      </FAQSection>
      <FAQSection title="PROCESS">
        <FAQ question="What is the process that follows once you've been hired?">
          After you've contacted me, I'll promptly respond with the next steps
          to proceed. Typically, I'll request that you complete a project
          application that I will provide, ensuring I have all the essential
          project details.
          <span className="space"></span>
          Following that, we can schedule a phone call, video call, or an
          in-person meeting, whichever you prefer, to have a more in-depth
          discussion about your project requirements. Once we reach an agreement
          on the project, we will formalize it in a contract signed by both
          parties.
        </FAQ>
        <FAQ question="How do you manage licensing and copyright matters for your compositions?">
          After I've provided my services, I will retain ownership, copyright,
          and the rights to the compositions. However, you will be granted a
          lifetime license to use the material for the specific project it was
          created for.
          <span className="space"></span>
          Additionally, if necessary, you will have the opportunity to extend
          your initial lifetime license to include your other projects.
        </FAQ>
      </FAQSection>
      <FAQSection title="OTHER">
        <FAQ question="Are revisions included in your services?">
          Certainly. My priority is your satisfaction with the material I
          provide. Each composition will require your approval before I proceed
          further, and I'm open to making multiple revisions, provided they are
          reasonable to ensure the final compositions achieve your desired
          outcome.
        </FAQ>
        <FAQ question="Do you charge based on an hourly rate or by the project?">
          Typically, I work on a project basis; however, the compensation
          structure, whether it's a flat fee, based on viewership, or determined
          by the total amount of music minutes required, will be decided based
          on the specific project's requirements, which may include additional
          fees.
        </FAQ>
        <FAQ question="How does the payment process work?">
          As per the terms of the contract, a fifty percent payment is due
          upfront to secure services and initiate the agreed-upon terms. The
          remaining balance is required upon completion of the contract or
          project. Should the final payment not be received, legal proceedings
          will be initiated.
        </FAQ>
        <FAQ question="What is your policy regarding refunds or cancellations?">
          The policy regarding refunds or cancellations is designed to be fair
          to both parties. I understand that circumstances can change, so I may
          offer a refund on a case-by-case basis.
          <span className="space"></span>
          Typically, if a cancellation is requested before work has commenced, I
          can offer a full refund. However, if work has already begun, a partial
          refund may be provided depending on the amount of work completed. I
          believe in open communication and will work with my clients to find
          the best solution for both parties in the event of a cancellation.
          Refunds are based entirely on our discretion.
        </FAQ>
        <FAQ question="Are you open to collaborations with other artists or musicians?">
          While I normally compose independently, I'm definitely open to
          collaborations with other composers or musicians, having had prior
          experience in this particular area.
        </FAQ>
        <FAQ question="Can you share references or testimonials from your past clients?">
          At this very moment, I don't have any available, but I'm actively
          working on compiling a client list of individuals who are open to
          being contacted for this specific purpose. I anticipate having
          references available at the start of the year 2025.
        </FAQ>
        <FAQ question="Where can I listen to your compositions and explore your previous works and projects?">
          You can explore all my projects on the{" "}
          <a href="/media" target="_blank" rel="noopener noreferrer">
            media page
          </a>
          , where you'll find everything I've created and that I'm currently
          working on.
        </FAQ>
        <FAQ question="What's your average response time for inquiries, and how can I go about filing one?">
          I typically respond on the same day, but I kindly request a minimum of
          2 weekdays (48 hours) before sending a follow-up message, allowing me
          time to address your inquiry. You can file an inquiry via the{" "}
          <a href="#contact">contact page</a>.
        </FAQ>
        <FAQ question="How I can donate to you and your studio to help support your music and future projects?">
          Thank you for your interest in supporting me! You can make a donation
          via the "DONATE" link at the top right of this website or by clicking{" "}
          <a
            href="https://paypal.me/JVKStudios"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          . Your contribution will greatly help fund my music and future
          projects.
        </FAQ>
      </FAQSection>
    </div>
  );
}
