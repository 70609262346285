import React from "react";
import NewsItem from "./NewsItem.jsx";

export default function News() {
  return (
    <div id="news">
      <NewsItem date="DECEMBER 3, 2024">
        <p>
          I've been putting a lot of effort into refining my track,{" "}
          <a href="#root">SPRING</a>, and I'm excited to share that it's coming
          together really well. Currently. I'd say it's about 80% complete, with
          most of the core elements in place and just a few finishing touches
          left to finalize. My goal is to have it fully polished and ready for
          release in the first half of next month.
        </p>
        <div style={{ paddingLeft: "10px" }}>
          <h4>ADDED</h4>
          <ul>
            <li>
              3 Layers of String of: <a href="#root">Albion Tundra</a>
            </li>
            <li>
              Core Piano Layer: <a href="#root">Noire</a>
            </li>
          </ul>
          <h4>IMPROVED</h4>
          <ul>
            <li>All String Autommations (7)</li>
          </ul>
        </div>
        <p>
          You can check it out <a href="#root">here</a> with all the modifications
          mentioned above. Don't forget to share your feedback using the{" "}
          <a href="#contact">contact form</a>.
        </p>
      </NewsItem>
      <NewsItem date="NOVEMBER 9, 2024">
        <p>
          I've been putting a lot of effort into refining my track,{" "}
          <a href="#root">SPRING</a>, and I'm excited to share that it's coming
          together really well. Currently. I'd say it's about 80% complete, with
          most of the core elements in place and just a few finishing touches
          left to finalize. My goal is to have it fully polished and ready for
          release in the first half of next month.
        </p>
        <div style={{ paddingLeft: "10px" }}>
          <h4>ADDED</h4>
          <ul>
            <li>
              3 Layers of String of: <a href="#root">Albion Tundra</a>
            </li>
            <li>
              Core Piano Layer: <a href="#root">Noire</a>
            </li>
          </ul>
          <h4>IMPROVED</h4>
          <ul>
            <li>All String Autommations (7)</li>
          </ul>
        </div>
        <p>
          You can check it out <a href="#root">here</a> with all the modifications
          mentioned above. Don't forget to share your feedback using the{" "}
          <a href="#contact">contact form</a>.
        </p>
      </NewsItem>
      <NewsItem date="OCTOBER 20, 2024">
        <p>
          I've been putting a lot of effort into refining my track,{" "}
          <a href="#root">SPRING</a>, and I'm excited to share that it's coming
          together really well. Currently. I'd say it's about 80% complete, with
          most of the core elements in place and just a few finishing touches
          left to finalize. My goal is to have it fully polished and ready for
          release in the first half of next month.
        </p>
        <div style={{ paddingLeft: "10px" }}>
          <h4>ADDED</h4>
          <ul>
            <li>
              3 Layers of String of: <a href="#root">Albion Tundra</a>
            </li>
            <li>
              Core Piano Layer: <a href="#root">Noire</a>
            </li>
          </ul>
          <h4>IMPROVED</h4>
          <ul>
            <li>All String Autommations (7)</li>
          </ul>
        </div>
        <p>
          You can check it out <a href="#root">here</a> with all the modifications
          mentioned above. Don't forget to share your feedback using the{" "}
          <a href="#contact">contact form</a>.
        </p>
      </NewsItem>
      <NewsItem date="SEPTEMBER 21, 2024">
        <p>
          I've been putting a lot of effort into refining my track,{" "}
          <a href="#root">SPRING</a>, and I'm excited to share that it's coming
          together really well. Currently. I'd say it's about 80% complete, with
          most of the core elements in place and just a few finishing touches
          left to finalize. My goal is to have it fully polished and ready for
          release in the first half of next month.
        </p>
        <div style={{ paddingLeft: "10px" }}>
          <h4>ADDED</h4>
          <ul>
            <li>
              3 Layers of String of: <a href="#root">Albion Tundra</a>
            </li>
            <li>
              Core Piano Layer: <a href="#root">Noire</a>
            </li>
          </ul>
          <h4>IMPROVED</h4>
          <ul>
            <li>All String Autommations (7)</li>
          </ul>
        </div>
        <p>
          You can check it out <a href="#root">here</a> with all the modifications
          mentioned above. Don't forget to share your feedback using the{" "}
          <a href="#contact">contact form</a>.
        </p>
      </NewsItem>
    </div>
  );
}
