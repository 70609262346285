import React from "react";
import "./styles/NewsItem.css";

export default function NewsItem({ date, children: news }) {
  let wait = false;

  function toggleNewsItem(event) {
    if (!wait) {
      wait = true;
      const news = event.currentTarget;

      if (news.getAttribute("data-open") === "false") {
        openNewsItem(news);
      } else {
        closeNewsItem(news);
      }

      setTimeout(() => (wait = false), 400);
    }
  }

  function openNewsItem(element) {
    const newsItem = element.parentElement;
    const icon = element.querySelector("svg");
    const news = newsItem.querySelector(".news");
    const currentOpenNews = document.querySelector(".date[data-open=true]");

    // close other open News items's
    if (currentOpenNews) {
      closeNewsItem(currentOpenNews);
    }

    icon.style.transform = "rotate(0deg)";
    element.setAttribute("data-open", "true");
    newsItem.style.height = newsItem.clientHeight + news.clientHeight + 15 + "px";
  }

  function closeNewsItem(element) {
    const newsItem = element.parentElement;
    const icon = element.querySelector("svg");

    icon.style.transform = "rotate(-225deg)";
    element.setAttribute("data-open", "false");
    newsItem.removeAttribute("style");
  }

  return (
    <div className="news-item">
      <p className="date" onClick={toggleNewsItem} data-open="false">
        {date}
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16px"
            viewBox="0 0 24 24"
            width="16px"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
          </svg>
        </button>
      </p>
      <div className="news">{news}</div>
    </div>
  );
}
