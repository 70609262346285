import React from "react";
import "./styles/Footer.css";
import Connect from "./Connect";
import FooterLinks from "./FooterLinks";

export default function Footer() {
  const path = window.location.pathname.replaceAll("/", "");
  const root = path === "" || path === "about" || path === "home";

  return (
    <>
      <Connect />
      <FooterLinks />
      <footer>
        <div className="footer-left">
          &copy; 2024 - {new Date().getFullYear() + 1}
          &nbsp;
          <a href="/">JVK STUDIOS</a>. ALL RIGHTS RESERVED.
        </div>
        <div className="footer-center">
          <a
            href="/"
            onClick={
              root
                ? (e) => {
                    e.preventDefault();
                    document.querySelector("#root").scrollIntoView();
                    window.history.replaceState("page", "", "/");
                  }
                : null
            }
          >
            <img
              src="/images/header-jvk-logo.png"
              alt="JVK"
              draggable="false"
            />
          </a>
        </div>
        <div className="footer-right">
          <a href="#root">
            BACK TO TOP
            <svg viewBox="0 0 13 13" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.58376 2.73002L2.37551 6.93827L1.66841 6.23116L6.7102 1.18937L7.06375 0.835814L7.41731 1.18937L12.6326 6.40469L11.9255 7.11179L7.5846 2.77087L7.73721 11.7916L6.73735 11.8085L6.58376 2.73002Z"
              />
            </svg>
          </a>
        </div>
      </footer>
    </>
  );
}
