import React from "react";
import "./styles/FooterLinks.css";

export default function FooterLinks() {
  return(
    <div id="footer-links">
      <div>
        <h3>NAVIGATION</h3>
        <ul>
          <li><a href="#root">HOME</a></li>
          <li><a href="#root">ABOUT</a></li>
          <li><a href="#root">MEDIA</a></li>
          <li><a href="#root">STORE</a></li>
          <li><a href="#root">CONTACT</a></li>
        </ul>
      </div>
      <div>
        <h3>LEGAL</h3>
        <ul>
          <li><a href="#root">DISCLAIMER</a></li>
          <li><a href="#root">PRIVACY POLICY</a></li>
          <li><a href="#root">TERMS OF SERVICE</a></li>
        </ul>
      </div>
      <div>
        <h3>OTHERS</h3>
        <ul>
          <li><a href="#root">DOWNLOADS</a></li>
          <li><a href="#root">SITEMAP</a></li>
        </ul>
      </div>
      <div>
        <h3>PERSONAL</h3>
        <ul>
          <li><a href="#root">JVK DESIGNS</a></li>
          <li><a href="#root">JVK GAMING</a></li>
          <li><a href="#root">JVK PODCAST</a></li>
          <li><a href="#root">JVK STUDIOS</a></li>
        </ul>
      </div>
      <div>
        <h3>COMPANY</h3>
        <ul>
          <li><a href="#root">KOMPLETE MEDIA</a></li>
          <li><a href="#root">KOMPLETE NEWS</a></li>
          <li><a href="#root">KOMPLETE PODCAST</a></li>
          <li><a href="#root">KOMPLETE STUDIOS</a></li>
        </ul>
      </div>
    </div>
  );
}