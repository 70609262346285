import React, { useEffect, useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "./styles/Slider.css";

export default function Slider({setNewReason}) {
  const images = require.context("../assets/images/slider", true);
  const sliderImages = images.keys().map((image) => images(image));

  let sI = [];

  sI.push(sliderImages[sliderImages.length - 2]);
  sI.push(sliderImages[sliderImages.length - 1]);

  for (let i = 0; i < sliderImages.length - 2; i++) {
    sI.push(sliderImages[i]);
  }

  const splideRef = useRef();

  const [sliderActions] = useState([
    {
      url: "/home?=contact",
      action: () => {
        window.history.pushState("", "", "/home?=contact");
        document.querySelector("#contact").scrollIntoView();
        setNewReason(1);
      },
    },
    {
      url: "/media?id=a01t01",
      action: () => (window.location = "/media?id=a01t01"),
    },
    {
      url: "/media?id=a01t01",
      action: () => (window.location = "/media?id=a01t01"),
    },
    {
      url: "/media?id=a01t01",
      action: () => (window.location = "/media?id=a01t01"),
    },
    {
      url: "/media?id=a01t01",
      action: () => (window.location = "/media?id=a01t01"),
    },
    {
      url: "/media?id=a01t01",
      action: () => (window.location = "/media?id=a01t01"),
    },
  ]);

  const [index, setIndex] = useState(0);
  const [wait, setWait] = useState(false);

  useEffect(() => {
    if (splideRef.current) {
      const splide = splideRef.current.splide;
      const { Autoplay } = splide.Components;

      const m2 = document.querySelector("#m2");
      const m1 = document.querySelector("#m1");
      const p1 = document.querySelector("#p1");
      const p2 = document.querySelector("#p2");
      const slider = document.querySelector(".slider");
      const prev = document.querySelector(".prev-img");
      const next = document.querySelector(".next-img");

      function move(pos) {
        if (!wait) {
          splide.go(pos);
          setWait(true);
          setTimeout(() => setWait(false), 800);
        }
      }

      //slide clicks
      m2.onclick = () => move("-1");
      m1.onclick = () => move("-1");
      p1.onclick = () => move("+1");
      p2.onclick = () => move("+1");

      //play
      function start() {
        if (!hov() && !min()) {
          Autoplay.play();
        } else {
          Autoplay.pause();
        }
      }

      //pause on hover
      function hov() {
        return !(
          document.querySelector(".overlay:hover") == null &&
          document.querySelector("#sliderButtonsB:hover") == null
        );
      }

      //pause on minimise
      function min() {
        return !document.hasFocus();
      }

      document.querySelectorAll(".overlay").forEach((el) => {
        el.onmouseover = () => start();
        el.onmouseout = () => start();
      });

      document.querySelector("#sliderButtonsB").onmouseover = () => start();
      document.querySelector("#sliderButtonsB").onmouseout = () => start();
      window.onfocus = () => start();
      window.onblur = () => start();

      //slider bump
      m2.onmouseover = () => {
        slider.style.left = "40px";
        prev.style.right = "90px";
        next.style.left = "10px";
      };
      m2.onmouseout = () => {
        slider.style.left = "0";
        prev.style.right = "50px";
        next.style.left = "50px";
      };

      m1.onmouseover = () => {
        slider.style.left = "40px";
        prev.style.right = "90px";
        next.style.left = "10px";
      };
      m1.onmouseout = () => {
        slider.style.left = "0";
        prev.style.right = "50px";
        next.style.left = "50px";
      };

      p1.onmouseover = () => {
        slider.style.left = "-40px";
        prev.style.right = "10px";
        next.style.left = "90px";
      };
      p1.onmouseout = () => {
        slider.style.left = "0";
        prev.style.right = "50px";
        next.style.left = "50px";
      };

      p2.onmouseover = () => {
        slider.style.left = "-40px";
        prev.style.right = "10px";
        next.style.left = "90px";
      };
      p2.onmouseout = () => {
        slider.style.left = "0";
        prev.style.right = "50px";
        next.style.left = "50px";
      };

      //timer buttons
      for (let i = 0; i < splide.length; i++) {
        document.querySelector("#bgSB" + (i + 1)).onclick = () => {
          const current = splide.index;
          const length = splide.length;
          const backward =
            i === current
              ? 0
              : i < current
              ? current - i
              : length + current - i;
          const forward =
            i === current
              ? 0
              : i > current
              ? i - current
              : length + i - current;
          if (backward !== 0 && forward !== 0) {
            splide.go(
              backward < forward
                ? `-${backward}`
                : forward < backward
                ? `+${forward}`
                : i < current
                ? `-${backward}`
                : `+${forward}`
            );
          }
        };
      }

      splide.on("move", (newIndex) => {
        setIndex(newIndex);
        document.querySelector(".sbol").style.width =
          "calc((100% / " + splide.length + ") * " + (newIndex + 1) + ")";
      });
    }
  });

  return (
    <div className="slider">
      <Splide
        ref={splideRef}
        options={{
          type: "loop",
          speed: 800,
          width: 5990,
          heigth: 468,
          fixedWidth: 1198,
          fixedHeight: 468,
          gap: 0,
          arrows: false,
          pagination: false,
          drag: true,
          waitForTransition: false,
          autoplay: true,
          interval: 8000,
          pauseOnHover: false,
        }}
      >
        {sI.map((image, index) => (
          <SplideSlide key={index}>
            <img src={image} alt={"slider image " + index} />
          </SplideSlide>
        ))}
      </Splide>

      <div className="overlay">
        <div id="m2"></div>
        <div id="m1">
          <svg className="slider-arrow prev-img" width="22px" height="40px">
            <polyline fill="#dddddd" points="22,0 0,20 22,40"></polyline>
          </svg>
        </div>
        <div id="n0">
          {sliderActions[index] !== null ? (
            <a
              href={sliderActions[index].url}
              style={{ display: "contents" }}
              onClick={(e) => e.preventDefault()}
            >
              <button onClick={sliderActions[index].action}></button>
            </a>
          ) : (
            <></>
          )}
        </div>
        <div id="p1">
          <svg className="slider-arrow next-img" width="22px" height="40px">
            <polyline fill="#dddddd" points="0,0 22,20 0,40"></polyline>
          </svg>
        </div>
        <div id="p2"></div>
      </div>
      <div id="sliderButtonsB" className="sbbh">
        <div
          className="sbol"
          style={{ width: `calc(100% / ${sliderImages.length})` }}
        ></div>
        {sliderImages.map((image, index) => (
          <div className="SBB" key={index}>
            <div className="sbb" id={`bgSB${index + 1}`}>
              <span></span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
